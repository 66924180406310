import { httpApiGet, httpApiPost, httpApiUploadFile } from "../helpers/axiosPrivate";

const initialState = {
	profileLoaded:false,
	loading: false,
	error: false,
	message: '',
	profileData:{
		name: null,
		lastname: null,
		email: null,
		profilePicture:null,
	},
}

const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS'
const PROFILE_ACTION_FAILURE = 'PROFILE_ACTION_FAILURE'
const START_LOADING_PROFILE = 'START_LOADING_PROFILE'
const STOP_LOADING_PROFILE = 'STOP_LOADING_PROFILE'
const SHOW_PROFILE_MESSAGE = 'SHOW_PROFILE_MESSAGE'
const RESET_PROFILE_MESSAGE = 'RESET_PROFILE_MESSAGE'

export default function reducer(state = initialState, action){
	switch (action.type) {
		case GET_PROFILE_SUCCESS:
			return {
				...state, 
				profileLoaded:true,
				profileData:{...state.profileData,...action.payload}
			}
		case PROFILE_ACTION_FAILURE:
			return {
				...state, 
				message: action.message,
				error: true
			}
		case START_LOADING_PROFILE:
			return {
				...state, 
				loading: true,
			}
		case STOP_LOADING_PROFILE:
			return {
				...state, 
				loading: false,
			}
		case SHOW_PROFILE_MESSAGE:
			return {
				...state, 
				message: action.message,
				error: action.payload
			}
		case RESET_PROFILE_MESSAGE:
			return {
				...state, 
				message: '',
				error: false
			}
		default:
			return state;
	}
}

export const getProfileAction = () => async (dispatch, getState) => {
	dispatch({
		type: START_LOADING_PROFILE,
	});
	try {
		const data = await httpApiGet('users/profile');
		if(data){
			dispatch({
				type: GET_PROFILE_SUCCESS,
				payload: data,
			});
		}
	} catch (error) {
		dispatch({
			type: PROFILE_ACTION_FAILURE,
			message: error.message,
		});
	}finally{
		dispatch({
			type: STOP_LOADING_PROFILE,
		});
	}
}

export const saveProfilePictureAction = (data) => async (dispatch, getState) => {
	dispatch({
		type: START_LOADING_PROFILE,
	});

	try {
		const response = await httpApiUploadFile('users/update', data);
		if(response){
			dispatch({
				type: GET_PROFILE_SUCCESS,
				payload: {
					profilePicture:response.profilePicture
				},
			});
			dispatch({
				type: SHOW_PROFILE_MESSAGE,
				message: response.message,
				payload:false
			});
		}
	} catch (error) {
		dispatch({
			type: PROFILE_ACTION_FAILURE,
			message: error.message,
		});
	}finally{
		dispatch({
			type: STOP_LOADING_PROFILE,
		});
	}
}
export const resetProfileErrorMessage = () => async (dispatch, getState) => {
	dispatch({
		type: RESET_PROFILE_MESSAGE,
	});
}