import React from 'react'

const Loading = (props) => {
	const {
		inner
	} = props
	return (
		<div className={`loadingContainer${inner?'Inner':''}`}>
			<div className={`rainbow ${inner?'inner':''}`}>
				<div className="circle1"></div>
			</div>
		</div>
	);
}

export default Loading;