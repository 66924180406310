import React, { lazy, Suspense } from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import { DASHBOARD_ROUTE } from './routes/dashboardRoutes';
import Fallback from './components/utils/Fallback';
import ErrorBoundary from './ErrorBoundary';
import App from './App';
import Signin from './screens/auth/Signin';
import Forgot from './screens/auth/Forgot';
import Reset from './screens/auth/Reset';
import ActivateAcount from './screens/auth/ActivateAcount';
import PrivateRoute from './auth/PrivateRoute';
import BaseRedirection from './containers/BaseRedirection';
import PageNotFound from './containers/PageNotFound';
import LoginWithToken from './screens/auth/LoginWithToken';

const Dashboard = lazy(() => import('./containers/dashboard/Dashboard'));

const Routes = () => {
    return (
        <Suspense fallback={ <Fallback />} >
            <ErrorBoundary>
                <BrowserRouter>
                    <Switch>
                        {/* <Route path="/" exact component={App} /> */}
                        <Route path="/ingresar" exact component={Signin} />
                        <Route path="/recuperar-cuenta" exact component={Forgot} />
                        <Route path="/recuperar-cuenta/:token" exact component={Reset} />
                        <Route path="/activar-cuenta/:token" exact component={ActivateAcount} />
                        <Route path="/baseRedirection" exact component={BaseRedirection} />
                        <Route path="/acceso-saleforce" exact component={LoginWithToken} />

                        <PrivateRoute path={DASHBOARD_ROUTE.HOME} component={Dashboard} />
                        <Route exact component={PageNotFound} />

                    </Switch>
                </BrowserRouter>
            </ErrorBoundary>
        </Suspense>
    );
};

export default Routes;