import * as yup from "yup";
import { passwordRegex } from "../../components/utils/StringValidator";

// min 5 characters, 1 upper case letter, 1 lower case letter, 1 numeric digit.

export const forgotSchema = yup.object().shape({
  email: yup.string()
    .email("Por favor introduzca un correo electrónico válido")
    .required("Este campo es obligatorio"),
});

export const loginSchema = yup.object().shape({
  email: yup.string()
    .email("Por favor introduzca un correo electrónico válido")
    .required("Este campo es obligatorio"),
  password: yup
    .string()
    .min(8, "La contraseña debe tener al menos 8 caracteres")
    .required("Este campo es obligatorio"),
});


export const resetPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .min(8)
    .matches(passwordRegex, { message: "Por favor introduzca una contraseña más segura" })
    .required("Este campo es obligatorio"),
  password_confirmation: yup
    .string()
    .oneOf([yup.ref("password"), null], "Las contraseñas no coinciden")
    .required("Este campo es obligatorio")
});