const API_URL =  process.env.REACT_APP_API;
const APP_NAME =  process.env.REACT_APP_NAME;
const PRODUCTS_PER_PAGE =  process.env.REACT_APP_PRODUCTS_PER_PAGE;
const PAGINATION_DEFAULT_SIZE =  process.env.REACT_APP_PAGINATION_DEFAULT_SIZE;
const TIMEOUT = 5000; // time in ms
const ERROR_MESSAGE = 'Oops hubo un error';
const AWS_IMAGE_URL = process.env.REACT_APP_IMAGE_URL;



export {
    API_URL,
    APP_NAME,
    TIMEOUT,
    ERROR_MESSAGE,
    PRODUCTS_PER_PAGE,
    PAGINATION_DEFAULT_SIZE,
    AWS_IMAGE_URL
};