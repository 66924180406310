import React, { useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.min.css';
import ForgotForm from '../../components/auth/ForgotForm';

const Forgot = () => {
	useEffect(() => {
		document.getElementById('body').classList.add('loginContainer');
	}, []);

	return (
		<main className="form-signin">
			<div className="bg"></div>
			<div className="container signIn">
				<div className="row w-100"/>
				<div className="row w-100">
					<div className="col-md-12 col-12">
						<img className="logInLogoUno" src="/assets/img/logo_vertical.svg" alt=""/>
					</div>
					<div className="col-xl-4 col-lg-6 col-md-6 col-12 mx-auto h-100">
						<ForgotForm/>
					</div>
				</div>
				<div className="row w-100">
					<div className="col-md-12 col-12">
						<img className="logInLogoDos" src="/assets/img/logo_viacottone.svg" width="100%" alt="Logo Viacotone"/>
					</div>
				</div>
			</div>
		</main>
	)
}

export default Forgot;
