import React from 'react';
import {Redirect} from 'react-router-dom';
import { logged } from '../auth/helpers';

const BaseRedirection = () => {
    if(!logged()){
        return <Redirect to={'/ingresar'}/>
    }else{
        return <Redirect to={'/'}/>
    }
};

export default BaseRedirection;