import React from 'react';
import ReactDOM from 'react-dom';
import Routes from './Routes';
import { Provider } from 'react-redux';
import generateStore from './services/redux/store'

const store = generateStore();

ReactDOM.render(
		<Provider store={store}>
			<Routes />
		</Provider>,
	document.getElementById('root')
);