import React, { useState, useEffect } from 'react';
import { Overlay, Tooltip} from 'react-bootstrap';
import { 
	Check, 
	X 
} from 'react-feather';
import { 
	containsWhiteSpace, 
	hasLowerCase, 
	hasNCharacters, 
	hasNumber, 
	hasSpecialCharacter, 
	hasUpperCase 
} from './StringValidator';

const PasswordValidatorTooltip = (props) => {
	const {
		showTooltip,
		passwordRef,
		password,
		placement="left"
	} = props
	const [passwordLength, setPasswordLength] = useState(false);
	const [passwordUppercase, setPasswordUppercase] = useState(false);
	const [passwordLowercase, setPasswordLowercase] = useState(false);
	const [passwordNumber, setPasswordNumber] = useState(false);
	const [passwordSpecialCharacter, setPasswordSpecialCharacter] = useState(false);

	useEffect(() => {
		if (password) {
			checkPasswordConditions(password);
		}
	}, [password]);

	const checkPasswordConditions = (password) => {
		setPasswordLength(hasNCharacters(password,8));
		setPasswordUppercase(hasUpperCase(password));
		setPasswordLowercase(hasLowerCase(password));
		setPasswordNumber(hasNumber(password));
		setPasswordSpecialCharacter(hasSpecialCharacter(password) && !containsWhiteSpace(password));
	}

	return (
		<Overlay target={passwordRef} show={showTooltip} placement={placement}  arrowProps={ {color:'red',borderColor:'red',backgroundColor:'red'}}>
			{(props) => (
			<Tooltip {...props}>
				<div>
					La contraseña debe contener al menos 8 caracteres
					{passwordLength ? <Check className="ml-15" color="green" /> : <X className="ml-15" color={"red"} />}
				</div>
				<div>
					Minúscula
					{passwordLowercase ? <Check className="ml-15" color="green" /> : <X className="ml-15" color={"red"} />}
				</div>
				<div>
					Mayúscula
					{passwordUppercase ? <Check className="ml-15" color="green" /> : <X className="ml-15" color={"red"} />}
				</div>
				<div>
					Número
					{passwordNumber ? <Check className="ml-15" color="green" /> : <X className="ml-15" color={"red"} />}
				</div>
				<div>
					Carácter especial y sin espacios en blanco
					{passwordSpecialCharacter ? <Check className="ml-15" color="green" /> : <X className="ml-15" color={"red"} />}
				</div>
			</Tooltip>
			)}
		</Overlay>
	);
};

export default PasswordValidatorTooltip;