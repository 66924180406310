import React, { useEffect } from 'react';
import ResetForm from '../../components/auth/ResetForm';

const ActivateAcount = ({ match }) => {

	useEffect(() => {
		document.getElementById('body').classList.add('loginContainer');
	}, [])

	return (
		<main className="form-signin">
			<div className="bg"></div>
			<div className="container signIn">
				<div className="row w-100"/>
				<div className="row w-100">
					<div className="col-md-12 col-12">
						<img className="logInLogoUno" src="/assets/img/logo_vertical.svg" alt=""/>
					</div>
					<div className="col-xl-4 col-lg-6 col-md-6 col-12 mx-auto h-100">
						<ResetForm
							token={match.params.token}
							url={`${process.env.REACT_APP_API}/auth/activate-account`}
							buttonMessage="Activar cuenta"
						/>
					</div>
				</div>
				<div className="row w-100">
					<div className="col-md-12 col-12">
						<img className="logInLogoDos" src="/assets/img/logo_viacottone.svg" width="100%" alt="Logo Viacotone"/>
					</div>
				</div>
			</div>
		</main>
	)
}

export default ActivateAcount;
