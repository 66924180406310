import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import LoadingOverlayed from '../utils/LoadingOverlay'
import { useFormik } from "formik";
import { forgotSchema } from "../../schemas/auth";
import CustomInput  from "../utils/CustomInput";

const ForgotForm = () => {
	const [showLoadingOverlayed, setShowLoadingOverlayed] = useState(false);

	const onSubmit = async (values, actions) => {
		setShowLoadingOverlayed(true);

		axios({
			method: 'POST',
			url: `${process.env.REACT_APP_API}/auth/reset-password`,
			headers: {
				'Content-Type': 'application/json'
			},
			data: { 
				email:values.email.toLowerCase() 
			}
		})
		.then(response => {
			toast.success(`Se envió un correo electrónico a ${values.email.toLowerCase()}. Siga las instrucciones para activar su cuenta.`);
		})
		.catch(error => {
			toast.error(error.response.data.error);
		}).finally(() => {
			setShowLoadingOverlayed(false);
		})
	};

	const {
		values,
		errors,
		touched,
		isSubmitting,
		handleBlur,
		handleChange,
		handleSubmit,
	} = useFormik({
		initialValues: {
			email: "",
		},
		validationSchema: forgotSchema,
		onSubmit,
	});

	return (
		<>
			<ToastContainer
				position="bottom-right"
			/>

			{showLoadingOverlayed &&
				<LoadingOverlayed />
			}
			<form onSubmit={handleSubmit} autoComplete="off" className="h-100">
				<div className="mb-4">
					<label className="form-label login" htmlFor="email">Email</label>
					<CustomInput
						handleChange={handleChange}
						id="email"
						type="email"
						values={values}
						className="form-control login"
						handleBlur={handleBlur}
						touched={touched}
						errors={errors}
					/>
				</div>

				<button type="submit" disabled={isSubmitting} className="w-100 btn btn-lg btn-primary mt-2 mb-5">Recuperar contraseña</button>
				<div className="passwordRecover">
					<Link to='/ingresar'>
						Iniciar sesión
					</Link>
				</div>
			</form>

		</>
	)
}

export default ForgotForm;
