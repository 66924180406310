import React from 'react';
import { signout } from './auth/helpers';
import { API_URL, APP_NAME } from './services/config';
import axios from 'axios';

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = {hasError: false};
	}
	
	static getDerivedStateFromError(error) {
		signout();
		return {hasError: true};
	}

	componentDidCatch(error, errorInfo) {
		axios.post(`${API_URL}/logger`, {
			 message: error.message,
			 stack: errorInfo.componentStack,
		 });
	 }

	render() {
		if (this.state.hasError) {
			return(
				<div className="container-fluid">
					<div className="row">
						<div className="col-xl-12 pa-0">
							<div className="auth-form-wrap pt-xl-0 pt-70">
								<div style={{textAlign:"center"}} className="auth-form w-100">
									<a  className="auth-brand text-center d-block mb-45" href="/">
										<img style={{width:"50%"}} src="/assets/img/logo_vertical.svg" alt={APP_NAME} />
									</a>
									<div>
										<h1 className="display-4 mb-10 text-center">Oops hubo un error.</h1>
										<p className="mb-30 text-center">
											 <a href="/"><u>Volver al inicio</u></a>.
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			); 
		}

		return this.props.children;
	}
}

export default ErrorBoundary;